<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <a-typography-title>
        Terms & Conditions
      </a-typography-title>
      <a-typography-title :heading="6">
        To receive services from ADS, doing business as ADS Inc; or ADS Logistics Inc., a written agreement for services
        must be in effect between the shipper and the aforementioned companies. If the shipper provides their shipments to
        ADS or allows ADS to collect their shipments for delivery without a written agreement for services, the shipper
        agrees to all terms and conditions outlined in this document.
      </a-typography-title>
      <a-typography-title :heading="6">
        Please note that the ADS service terms and conditions may be updated and are subject to change. If a shipment is
        picked up 24 hours after an update has been published online, the updated terms and conditions will apply.
      </a-typography-title>
      <a-typography-title :heading="6">
        Published: May, 2023
      </a-typography-title>
      <a-typography-title :heading="5">
        DEFINITIONS:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        In these terms and conditions, "we", "us", "our" and "Company" refer to ADS, while "you" and "your" refer to the
        customer using our logistics services. "Services" refer to the logistics services we provide, including but not
        limited to shipping, handling, warehousing, and delivery.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        AGREEMENT:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        By using our Services, you agree to these terms and conditions. These terms and conditions, along with any
        additional agreements and policies provided by our Company, constitute the entire agreement between you and our
        Company.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        SERVICES:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        Our Company provides logistics services in accordance with the terms and conditions set forth in this agreement.
        We reserve the right to refuse or terminate services to any customer at any time, for any reason.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        CUSTOMER RESPONSIBILITIES:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        You are responsible for accurately and truthfully providing all necessary information to us. This includes but is
        not limited to the weight and dimensions of the shipment, the destination address, and any necessary customs
        information. You are also responsible for properly packaging and labeling your shipment, in accordance with our
        packaging and labeling guidelines.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        FEES AND PAYMENTS:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        Our fees for the Services are set forth in our pricing schedule, which is subject to change at any time. You agree
        to pay all applicable fees for the Services, including any applicable taxes, surcharges, or additional fees.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        LIABILITY AND INSURANCE:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        Our liability for loss, damage, or delay of your shipment is limited to the actual value of the shipment or $100,
        whichever is less, unless additional insurance is purchased. We offer insurance coverage for an additional fee,
        which must be purchased at the time of shipment. We are not liable for any indirect, special, or consequential
        damages.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        INTELLECTUAL PROPERTY:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        Our Company retains all rights, title, and interest in and to all intellectual property associated with our
        Services. You may not reproduce, distribute, or use our intellectual property without our express written consent.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        CONFIDENTIALITY:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        All information and data provided to us by you or obtained by us in connection with the Services is considered
        confidential and will be kept confidential. We will not disclose any such information without your prior written
        consent, except as required by law.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        FORCE MAJEURE:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        Our Company will not be liable for any delay or failure in performing our obligations under this agreement if such
        delay or failure is caused by events beyond our control, including but not limited to acts of God, war, riot,
        fire, explosion, or natural disasters.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        GOVERNING LAW AND DISPUTE RESOLUTION:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        This agreement shall be governed by and construed in accordance with the laws of NJ, without giving effect to any
        choice of law or conflict of law provisions. Any disputes arising under or in connection with this agreement shall
        be resolved through binding arbitration in Somerset, in accordance with the rules of the American Arbitration
        Association.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        AMENDMENTS:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        We reserve the right to amend these terms and conditions at any time by providing you with notice of the
        amendment. Your continued use of our Services after such notice constitutes your acceptance of the amended terms
        and conditions.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        TERMINATION:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        Either party may terminate this agreement at any time, with or without cause, upon written notice to the other
        party. Upon termination, you will remain responsible for all fees and charges incurred prior to termination.
      </a-typography-paragraph>
      <a-typography-title :heading="5">
        ENTIRE AGREEMENT:
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        This agreement, together with any additional agreements and policies provided by our Company, constitutes the
        entire agreement between you and our Company, and supersedes all prior or contemporaneous agreements,
        representations, warranties, and understandings, whether oral or written.
      </a-typography-paragraph>
      <a-typography-title :heading="6" style="margin-top:60px;font-weight: 700;">
        By accepting the services of our company, you agree to be bound by these terms and conditions. If you have any
        questions or concerns about these terms and conditions, please do not hesitate to contact us.
      </a-typography-title>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku8.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .title1 {
    font-family: "Raleway";
    color: var(--color-text-1);
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
  }

  .title2 {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-text-2);
    margin-bottom: 20px;
  }

  .title3 {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-text-1);
    margin-bottom: 20px;
  }

  .title4 {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text-1);
    margin-bottom: 20px;
    padding-top: 10px;
  }

  .title5 {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text-2);
    margin-bottom: 10px;
  }

  .til-b {
    color: var(--color-text-1);
    margin-top: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 600;
  }
}
</style>
